import { RupiahHelper } from "@banklampung/helper";
import _ from "@banklampung/libs/lodash";
import dateFormat from "dateformat";
import validator from "validator";

class FormValidator {
  static getErrorMessage = (method, param) => {
    switch (method) {
      case "required": {
        return `Isian :label harus di isi`;
      }
      case "mustlen": {
        return `Panjang isian :label ${param} karakter`;
      }
      case "minlen": {
        return `Panjang isian :label minimal ${param} karakter`;
      }
      case "maxlen": {
        return `Panjang isian :label maksimal ${param} karakter`;
      }
      case "min": {
        return `Minimal nilai isian :label harus ${param}`;
      }
      case "max": {
        return `Maksimal nilai isian :label harus ${param}`;
      }
      case "len": {
        const [min, max] = JSON.parse(param);
        return `Rentang isian :label antara ${min} hingga ${max}`;
      }
      case "email": {
        return `Alamat email/surel tidak valid`;
      }
      case "phone": {
        return `Nomor HP tidak valid, harus 10-14 digit. Contoh: 08xxxxxxxx `;
      }
      case "number": {
        return `Isian :label harus bertipe angka`;
      }
      case "integer": {
        return `Isian :label harus bertipe bilangan bulat`;
      }
      case "url": {
        return `Isian url tidak valid`;
      }
      case "currency-formatted": {
        return `Isian tidak boleh 0`;
      }
      default: {
        return `Isian :label tidak valid`;
      }
    }
  };

  static validate = (element) => {
    const isCheckbox = element.type === "checkbox";
    const isFile = element.type === "file";
    const isMask = element.getAttribute("data-is-mask") === "1";
    const isCurrency = element.getAttribute("data-is-currency") === "1";

    let _value = element.value;
    if (isCheckbox) {
      _value = element.checked;
    } else if (isFile) {
      _value = element.files[0];
    } else if (isCurrency) {
        const valUnmask = element.value.replace(/[^$0-9\,]/g, "");
        const valNumber = valUnmask.replace(',','.');
        _value = parseFloat(valNumber);
    } else {
      if (isMask) {
        _value = element.value.replace(/\D+/g, "");
      }
    }

    const value = _value;

    const name = element.name;

    if (!name) throw new Error("Input name must not be empty.");

    const param = element.getAttribute("data-param");
    let validations = JSON.parse(element.getAttribute("data-validate"));

    // Add Manual Validation Message
    if (isCurrency) {
      validations = [
        ...validations ?? [],
        'currency-formatted'
      ];
    }

    let result = [];
    if (validations && validations.length) {
      validations.forEach((m) => {
        switch (m) {
          case "required":
            let req = false;
            if (isCheckbox) {
              req = value === false;
            } else if (isFile) {
              req = element.files.length === 0;
            } else {
              req = validator.isEmpty(value);
            }
            result[m] = req;
            break;
          case "email":
            result[m] = !validator.isEmail(value);
            break;
          case "phone": {
            const regex = /^(^08)(\d{3,4}-?){2}\d{2,4}$/;
            result[m] = !regex.test(value);
            break;
          }
          case "number":
            result[m] = !validator.isNumeric(value);
            break;
          case "integer":
            result[m] = !validator.isInt(value);
            break;
          case "alphanum":
            result[m] = !validator.isAlphanumeric(value);
            break;
          case "url":
            result[m] = !validator.isURL(value);
            break;
          case "equalto":
            const value2 = document.getElementById(param).value;
            result[m] = !validator.equals(value, value2);
            break;
          case "minlen":
            result[m] = !validator.isLength(value, { min: param });
            break;
          case "maxlen":
            result[m] = !validator.isLength(value, { max: param });
            break;
          case "len":
            const [min, max] = JSON.parse(param);
            result[m] = !validator.isLength(value, { min, max });
            break;
          case "mustlen":
            result[m] = !validator.isLength(value, { min: param });
            break;
          case "min":
            result[m] = !validator.isInt(value, {
              min: validator.toInt(param),
            });
            break;
          case "max":
            let vv = false;
            if (isFile) {
              if (value) {
                const fileSizeMb = value.size / 1024 / 1024;
                vv = parseFloat(fileSizeMb) > parseFloat(param);
              } else {
                vv = true;
              }
            } else {
              vv = !validator.isInt(value, {
                max: validator.toInt(param),
              });
            }
            result[m] = vv;
            break;
          case "list":
            const list = JSON.parse(param);
            result[m] = !validator.isIn(value, list);
            break;
          case "currency-formatted":
            if(value > 0){
              result[m] = false;
            }else{
              result[m] = true;
            }
            break;
          default:
            throw new Error("Unrecognized validator.");
        }
      });
    }

    return result;
  };

  static bulkValidate = (inputs) => {
    let errors = {},
      invalids = {},
      errorMessages = {},
      hasError = false;

    inputs.forEach((input) => {
      let param = input.getAttribute("data-param");
      let result = this.validate(input);
      let invalid = Object.keys(result).some((method) => result[method]);
      let errorMessage = Object.keys(result)
        .map((method) =>
          result[method] ? this.getErrorMessage(method, param) : ""
        )
        .filter((val) => val.length !== 0)
        .join("\n");

      errors = { ...errors, [input.name]: result };
      invalids = { ...invalids, [input.name]: invalid };
      errorMessages = { ...errorMessages, [input.name]: errorMessage };

      if (!hasError) hasError = invalid;
    });

    return {
      errors,
      hasError,
      invalids,
      errorMessages,
    };
  };

  static validateOnChange = (event) => {
    const input = event.target;

    const param = input.getAttribute("data-param");
    const isCheckbox = input.type === "checkbox";
    const isSelect = input.type === "select-one";
    const isFile = input.type === "file";
    const isDate = input.type === "date";
    const isTime = input.type === "time";
    const isLocalDateTime = input.type === "datetime-local";
    const isMask = input.getAttribute("data-is-mask") === "1";
    const isAmount = input.getAttribute("data-is-amount") === "1";
    const regexInput = input.getAttribute("data-regex-input");
    const isCapital = input.getAttribute("data-is-capital");
    const isNoDoubleSpace = input.getAttribute("data-no-double-space");
    const isNoSpace = input.getAttribute("data-no-space");
    const isPhone = input.getAttribute("data-phone");
    const isNumber = input.getAttribute("data-is-number");
    const isCurrency = input.getAttribute("data-is-currency");
    const isAlphaNum = input.getAttribute("data-is-alphanum");

    let value = input.value;

    if (isSelect) {
      value = {
        value: value,
        label: input.options[input.selectedIndex].text,
      };
    } else if (isMask) {
      let valUnmask = value.replace(/\D+/g, "");
      if (isAmount) {
        value = {
          value: valUnmask,
          formatted: RupiahHelper.toRupiah(valUnmask),
          speak: `${RupiahHelper.toTerbilang(valUnmask)} Rupiah`,
        };
      } else {
        value = {
          value: valUnmask,
          formatted: value,
        };
      }
    } else if (isLocalDateTime) {
      try {
        const valueFromatted = dateFormat(
          new Date(value),
          regexInput || "dd-mm-yyyy HH:mm:ss"
        );
        value = {
          value: value,
          formatted: valueFromatted,
        };
      } catch {}
    } else if (isDate) {
      try {
        const valueFromatted = dateFormat(
          new Date(value),
          regexInput || "dd-mm-yyyy"
        );
        value = {
          value: value,
          formatted: valueFromatted,
        };
      } catch {}
    } else if (isTime) {
      try {
        const valueFromatted = dateFormat(
          new Date(value),
          regexInput || "HH:mm:ss"
        );
        value = {
          value: value,
          formatted: valueFromatted,
        };
      } catch {}
    } else if (isCheckbox) {
      value = input.checked;
    } else if (isFile) {
      value = input.files[0];
    } else if (isCapital) {
      let inType = input.type;
      if (["textarea", "text"].includes(inType)) {
        value = `${value}`.toUpperCase();
      }
    } else if (isPhone) {
      if (value.length <= 2) {
        const regex = /^(^08|0)$/;
        value = regex.test(value)
          ? value
          : value.substring(0, value.length - 1);
      } else if (value.length > 14) {
        value = value.substring(0, 14);
      } else {
        const regex = /^[0-9]*$/;
        value = regex.test(value)
          ? value
          : value.substring(0, value.length - 1);
      }
    } else if (isAlphaNum) {
      const regex = /^[a-zA-Z0-9 ]*$/;
      value = regex.test(value) ? value : value.substring(0, value.length - 1);
    } else if (isNumber) {
      const regex = /^[0-9]*$/;
      value = regex.test(value) ? value : value.substring(0, value.length - 1);
    } else if (isCurrency) {
      if(validator.isNumeric(value)){
        return {
          value: 0,
          formatted: ""
        }
      }else{
        const valUnmask = value.replace(/[^$0-9\,]/g, "");
        const valNumber = valUnmask.replace(',','.');
        value = {
          value: parseFloat(valNumber),
          formatted: value, 
          speak: `${RupiahHelper.toTerbilang(valNumber)}`,
        };
      }
    }

    if (isNoDoubleSpace) {
      value = value.replace(/ +(?= )/g, "");
      value = value.replace(".", "");
    } else if (isNoSpace) {
      value = value.replace(/ +(?= )/g, "");
      value = value.trim();
    }

    const result = this.validate(input);
    const hasError = Object.keys(result).some((method) => result[method]);
    const errorMessage = Object.keys(result)
      .map((method) =>
        result[method] ? this.getErrorMessage(method, param) : ""
      )
      .filter((val) => val.length !== 0)
      .join("\n");

    return {
      input,
      value,
      hasError,
      errorMessage,
      result,
      isSelect,
      isCheckbox,
      isMask,
      isAmount,
      isDate,
      regexInput,
    };
  };

  static validateOnSelectSingle = (selectedOpt) => {
    let result = [],
      hasError = true,
      errorMessage = this.getErrorMessage("required", "");
    result["required"] = true;

    if (!_.isEmpty(selectedOpt)) {
      result["required"] = true;
      hasError = false;
      errorMessage = "";
    }

    return {
      result,
      hasError,
      errorMessage,
    };
  };
}

export default FormValidator;
