import { Card, FieldIcon, LoaderButton, FieldAgreement } from "@banklampung/components";
import { envName, isDev } from "@banklampung/config";
import { useForm, usePatchApi, usePostApi } from "@banklampung/hooks";
import * as Actions from "@banklampung/store/actions";
import * as GlobalActions from "@banklampung/store/actions";
import { SweetDialog } from "@banklampung/utils";
import CIcon from "@coreui/icons-react";
import { CBadge, CCol, CContainer, CForm, CRow } from "@coreui/react";
import PropTypes from "prop-types";
import React, { lazy } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AuthEndpoint } from "@app-endpoint";
import { HmacHelper } from "@banklampung/helper";

const ChangePassword = (props) => {
  const { appName, appVersion, companyName, departementName, logo, onSuccess } =
    props;

  const dispatch = useDispatch();

  const [, doSubmitLogout] = usePostApi({
    progressDialog: {
      show: false,
    },
    onSuccess: () => {
      dispatch(Actions.logoutUser());
    },
  });

  const [{ isLoading: isLoadingLogin }, doSubmit] = usePatchApi({
    progressDialog: {
      show: false,
    },
    onSuccess: ({ payload }) => onSuccessSubmit(payload),
  });

  const { form, handleChange, handleSubmit } = useForm({
    initialState: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      agree: false,
    },
    onSubmit: () => {
      dispatch(
        GlobalActions.openDialog({
          icon: "question",
          title: `Apakah Anda yakin?`,
          html: (
            <>
              Dengan mengganti password maka seluruh aplikasi yang terhubung
              dengan akun SSO Anda akan otomatis terganti. <br></br>
              <br></br>
              <small>
                <i>* Simpan dengan baik username dan password kamu ya...</i>
                &#128522;
              </small>
            </>
          ),
          confirmButtonText: "Siap. Lanjutkan",
          cancelButtonText: "Nanti dulu",
          showConfirmButton: true,
          showCancelButton: true,
          onConfirm: () => {
            dispatch(GlobalActions.closeDialog());

            setTimeout(() => {
              onSubmit();
            }, 100);
          },
        })
      );
    },
  });

  const onSubmit = () => {
    doSubmit({
      url: "auth/users/reset-password-by-me",
      body: {
        old_password: form.oldPassword,
        new_password: form.newPassword,
        confirm_password: form.confirmPassword,
      },
    });
  };

  const onSuccessSubmit = (payload) => {
    dispatch(
      GlobalActions.openDialog({
        icon: "success",
        title: `Yeayy... Berhasil`,
        html: (
          <>
            JANGAN PERNAH MEMBAGIKAN DATA USER DAN PASSWORD KEPADA SIAPAPUN
            TERMASUK REKAN KERJA<br></br>
            <br></br>
            <strong>#DatamuRahasiamu</strong> <br></br>
            <strong>#Waspadalah</strong>
          </>
        ),
        confirmButtonText: "Ya. Saya Paham",
        showConfirmButton: true,
        allowOutsideClick: false,
        onConfirm: () => {
          dispatch(GlobalActions.closeDialog());

          setTimeout(() => {
            (async () => {
              const url = `/${AuthEndpoint.LOGOUT_ENDPOINT ?? "auth/oauth/revoke"}`;
              const signHeaders = await HmacHelper.getHeader({
                method: "POST",
                path: url
              });
      
              doSubmitLogout({
                url: url,
                headers: signHeaders,
              });
            })();

            if (onSuccess) {
              onSuccess(payload);
            }
          }, 500);
        },
      })
    );
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <ToastContainer position="top-right" autoClose={5000} />

        <SweetDialog />

        <CRow className="justify-content-center">
          <CCol md="5">
            <Card className="p-4">
              <CForm
                onSubmit={handleSubmit}
                name="formLogin"
                className="d-flex flex-column"
              >
                <CIcon
                  name={logo}
                  width="220"
                  height="80"
                  className="align-self-center"
                  alt="logo-bank-lampung"
                />
                <h2 className="mt-2 align-self-center">{appName}</h2>

                {isDev ? (
                  <CBadge color="dark" className="align-self-center">
                    {envName}
                  </CBadge>
                ) : (
                  <CBadge color="primary" className="align-self-center">
                    {envName}
                  </CBadge>
                )}

                <p className="text-muted mt-3 mb-4 align-self-center">
                  Silahkan ganti password Anda terlebih dahulu
                </p>

                <FieldIcon
                  id="oldPassword"
                  label="Password Lama"
                  placeholder="Password Lama"
                  icon="cil-lock-locked"
                  type="input"
                  fieldType="password"
                  validate={`["required"]`}
                  invalid={form.invalids.oldPassword}
                  errorMessage={form.errorMessages.oldPassword}
                  onChange={handleChange}
                  defVal={form.oldPassword}
                />

                <FieldIcon
                  id="newPassword"
                  label="Password Baru"
                  placeholder="Masukan Password Baru"
                  icon="cil-lock-locked"
                  type="input"
                  fieldType="password"
                  validate={`["required", "len"]`}
                  param="[8, 16]"
                  invalid={form.invalids.newPassword}
                  errorMessage={form.errorMessages.newPassword}
                  onChange={handleChange}
                  defVal={form.newPassword}
                />

                <FieldIcon
                  id="confirmPassword"
                  label="Konfirmasi Password Baru "
                  placeholder="Masukan Konfirmasi Password Baru"
                  icon="cil-lock-locked"
                  type="input"
                  fieldType="password"
                  validate={`["required", "len"]`}
                  param="[8, 16]"
                  invalid={form.invalids.confirmPassword}
                  errorMessage={form.errorMessages.confirmPassword}
                  onChange={handleChange}
                  defVal={form.confirmPassword}
                />

                <FieldAgreement form={form} handleChange={handleChange} />

                <LoaderButton
                  propsIcon={{ name: "cil-paper-plane" }}
                  label="Ganti"
                  isLoading={isLoadingLogin}
                  className="align-self-start mt-3"
                />

                <div className="d-flex flex-column mt-5 align-self-center align-items-center">
                  <span>
                    {`${new Date().getFullYear()} @ ${departementName} - ${companyName}`}
                  </span>
                  <small className="text-muted">{`Versi ${appVersion}`}</small>
                </div>
              </CForm>
            </Card>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

ChangePassword.propTypes = {
  companyName: PropTypes.string,
  departementName: PropTypes.string,
  logo: PropTypes.string,
};

ChangePassword.defaultProps = {
  appName: "SSO BANK LAMPUNG",
  appVersion: "1.0.0-RELEASE",
  companyName: "PT Bank Lampung",
  departementName: "Divisi Teknologi Informasi",
};

export default ChangePassword;
