const BILLING_ENDPOINT        = "/alium/billings";
const TAX_ENDPOINT            = "/alium/taxes";
const TAXPAYER_ENDPOINT       = "/alium/taxpayers/inquiry";
const PAYMENT_MPN_ENDPOINT    = "/ficus/payment-mpn";

export default {
  BILLING_ENDPOINT,
  TAX_ENDPOINT,
  TAXPAYER_ENDPOINT,
  PAYMENT_MPN_ENDPOINT,
};
