import { cleanValue } from "@banklampung/helper";
import React from "react";

const RowRepeat = (props) => {
  const { style, trStyle, values, leftWidth, rightWidth, inverse=false, bodyStyle } = props;
  return (
    <table width="100%" style={style}>
      <tbody style={bodyStyle}>
        {values.map((obj, key) => (
          <tr key={key} style={trStyle}>
            <td 
              width={leftWidth} 
              className={inverse == false ? "font-dark font-weight-bold" : ""}
            >
              {obj.label}
            </td>
            <td
              className={inverse == true ? "font-dark font-weight-bold" : ""}
              width={rightWidth}
              style={{
                verticalAlign: "top",
              }}
            >
              : {cleanValue(obj.value)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

RowRepeat.defaultProps = {
  values: [],
  leftWidth: "20%",
  rightWidth: "80%",
};

export default RowRepeat;
