const INQUIRY_ENDPOINT  = "arau/inquiry";
const PAYMENT_ENDPOINT  = "arau/payments";
const CLUSTER_ENDPOINT  = "arau/clusters";
const SLIP_ENDPOINT     = "arau/slip";
const REPORT_ENDPOINT   = "arau/reports";
const CITY_ENDPOINT     = "arau/cities";
const OUTLET_ENDPOINT     = "arau/outlet";

export default {
    INQUIRY_ENDPOINT,
    PAYMENT_ENDPOINT,
    CLUSTER_ENDPOINT,
    SLIP_ENDPOINT,
    REPORT_ENDPOINT,
    CITY_ENDPOINT,
    OUTLET_ENDPOINT
};