class DateHelper {
  static getCurrentDate = ({ pattern }) => {
    const now = new Date();

    return this.toString(pattern, now);
  };

  static getCurrentTime = ({ pattern }) => {
    const now = new Date();
    return `${ this.toString(pattern, now) } ${this.pad(now.getHours())}:${this.pad(now.getMinutes())}:${this.pad(now.getSeconds())}`;
  }

  static getTomorow = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1); // Increment by 1 day
    const tomorow = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    return tomorow;
  }

  static getMaxDate = ({ pattern, substractYear = 0 }) => {
    const now = new Date();

    now.setFullYear(now.getFullYear() - substractYear);

    return this.toString(pattern, now);
  };

  static getFirstDayOfMonth = ({ pattern }) => {
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);

    return this.toString(pattern, firstDate);
  }

  static getLastDayOfMonth = ({ pattern }) => {
    const date = new Date();
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return this.toString(pattern, lastDate);
  }

  static toString = (pattern = "yyyy-MM-dd", now) => {
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let hour = now.getHours();
    let minute = now.getMinutes();
    let second = now.getSeconds();

    if (day < 10) {
      day = `0${day}`;
    }

    if (month < 10) {
      month = `0${month}`;
    }

    if (hour < 10) {
      hour = `0${hour}`;
    }

    if (minute < 10) {
      minute = `0${minute}`;
    }

    if (second < 10) {
      second = `0${second}`;
    }

    switch (pattern) {
      case "dd-MM-yyyy":
        return `${day}-${month}-${year}`;

      case "yyyy-mm-dd":
        return `${year}/${month}/${day}`;

      case "dd-MM-yyyy hh:mm:ss":
        return `${day}-${month}-${year} ${hour}:${minute}:${second}`;

      case "mm/dd/yyyy":{
        return `${month}/${day}/${year}`;
      }

      default:
        return `${year}-${month}-${day}`;
    }
  };

  static convDateResponse = (dtString, patern) => {
    const dtSplit = dtString.split(" ");
    const date = dtSplit[0];
    const time = dtSplit[1];

    const splitDate = date.split("-");
    const day   = splitDate[0];
    const month = splitDate[1];
    const year  = splitDate[2];

    switch(patern){
      case "yyyy-mm-dd":{
        return `${year}-${month}-${day}`;
      }

      case "mm/dd/yyyy":{
        return `${month}/${day}/${year}`;
      }
    }
  }

  static diffDate = (dt1, dt2, type) => {
    const date1 = new Date(dt1); //'7/13/2010'
    const date2 = new Date(dt2); //'mm/dd/yyyy'
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    switch (type) {
      case 'day':
        return diffDays;
    
      default:
        break;
    }
  }

  static pad = (number) => {
    if (number <= 99) {
      number = ("0" + number).slice(-2);
    }
    return number;
  };
  
  static convDateUTC7(dateString) {
    // Parse the input string into a JavaScript Date object (assume UTC+0)
    const [day, month, year, hours, minutes, seconds] = dateString.split(/[-\s:]/).map(Number);
    const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds)); // Month is 0-indexed

    // Add 7 hours for UTC+7
    utcDate.setHours(utcDate.getHours() + 7);

    // Format the output to a readable string
    return utcDate.toISOString().replace("T", " ").substring(0, 19); // Keep date and time only
  }
}

export default DateHelper;
